.tagline {
  text-align: center;
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 30px;

  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 1.1em;
    margin-top: 10px;
  }
}

.card {
  padding: var(--mantine-spacing-xl);
  align-self: stretch;
  max-width: 500px;

  @media (max-width: em(1080px)) {
    padding: var(--mantine-spacing-xl);
  }
}

.cardTitle {
  margin-bottom: var(--mantine-spacing-xl);
  line-height: 1;
}

.wrapper {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: var(--mantine-spacing-md);
}

.testimonials {
  margin: 40px auto;
  width: 80%;

  @media (max-width: $mantine-breakpoint-sm) {
    width: initial;
  }
}

.getStarted {
  /* Neutralize global.css .main-content padding */
  margin: 0 -40px -40px;
  padding: 50px;
  background-color: var(--mantine-color-adinSecondary-filled);
}

.logo {
  width: 100px;
  font-size: 0;
  margin-right: 20px;
}

.joinButton {
  border-radius: var(--mantine-radius-xl);
  padding-left: 60px;
  padding-right: 60px;
}

.joinButtonLabel {
  font-size: 16px;
  font-weight: 700;
}
