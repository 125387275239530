.card {
  width: 380px;
  box-shadow: 0 0 20px 0 #0000001A;
}

.title {
  min-height: 2.7lh;
}

.eventsTitle {
  text-align: center;
}

.eventInfo {
  flex: 1;
}

.eventDescription p {
  margin-top: 0;
  margin-bottom: 0;
}

.ribbonContainer {
  position: relative;
  overflow: initial;

  > .ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;

    > :only-child {
      font-size: 10px;
      font-weight: bold;
      color: #FFF;
      text-transform: uppercase;
      text-align: center;
      line-height: 20px;
      transform: rotate(45deg);
      width: 100px;
      display: block;
      background-color: var(--mantine-color-adinPrimary-filled);
      box-shadow: 0 3px 10px -5px rgb(0 0 0 / 100%);
      position: absolute;
      top: 19px;
      right: -21px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid var(--mantine-color-adinPrimary-filled);
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid var(--mantine-color-adinPrimary-filled);
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid var(--mantine-color-adinPrimary-filled);
        border-bottom: 3px solid transparent;
        border-top: 3px solid var(--mantine-color-adinPrimary-filled);
      }
    }
  }
}
